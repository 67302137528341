.ant-form-item-label {
    font-weight: 500;
}

.ant-input-affix-wrapper {
    padding: 8px 11px;
}

/* .ant-select-selector {
    height: 40px !important;
} */

.ant-select-selection-item {
    line-height: 38px !important;
}