/* Main Layout */
.main-layout {
    padding: 24px;
    background: #f6f8fa;
    min-height: 100vh;
  }
  
  /* Page Header */
  .page-header {
    margin-bottom: 32px;
    padding: 20px;
    /* background: linear-gradient(135deg, #1890ff 0%, #36cfc9 100%); */
    background: #323694;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .page-title {
    color: white !important;
    margin: 0 !important;
  }
  
  .status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Main Card */
  .main-card {
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  
  .main-card .ant-card-body {
    padding: 24px;
  }
  
  /* Custom Tabs */
  .custom-tabs .ant-tabs-nav {
    background: white;
    padding: 0 16px;
    margin-bottom: 24px !important;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }
  
  .custom-tabs .ant-tabs-nav::before {
    display: none;
  }
  
  .custom-tabs .ant-tabs-tab {
    padding: 12px 24px !important;
    margin: 0 8px !important;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .custom-tabs .ant-tabs-tab:hover {
    color: #1890ff;
    background: #e6f7ff;
  }
  
  .custom-tabs .ant-tabs-tab-active {
    background: #97c953 !important;
    border-radius: 8px !important;
  }
  
  .custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
  
  .custom-tabs .ant-tabs-tab-active:hover {
    background: #97c953 !important;
  }
  
  .tab-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .tab-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Form Elements */
  .ant-form-item {
    margin-bottom: 24px;
  }
  
  .ant-input,
  .ant-select-selector,
  .ant-input-number,
  .ant-picker {
    border-radius: 8px !important;
    border: 1px solid #d9d9d9 !important;
    transition: all 0.3s ease !important;
  }
  
  .ant-input:hover,
  .ant-select-selector:hover,
  .ant-input-number:hover,
  .ant-picker:hover {
    border-color: #1890ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1) !important;
  }
  
  .ant-input:focus,
  .ant-select-selector:focus,
  .ant-input-number:focus,
  .ant-picker:focus {
    border-color: #1890ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  }
  
  /* Buttons */
  .ant-btn {
    border-radius: 8px;
    height: 40px;
    padding: 0 20px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .ant-btn-primary {
    /* background: linear-gradient(135deg, #1890ff 0%, #36cfc9 100%); */
    background: linear-gradient(88deg, #8cc540, #2e3191);

    border: none;
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.35);
  }
  
  .ant-btn-primary:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.45);
    background: #8cc540 !important;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f0f2f5;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .main-layout {
      padding: 16px;
    }
  
    .page-header {
      padding: 16px;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }
  
    .custom-tabs .ant-tabs-tab {
      padding: 8px 16px !important;
      margin: 0 4px !important;
    }
  
    .tab-content {
      padding: 16px;
    }
  }